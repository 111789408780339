import { Controller } from "stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["detectedTimeZone"];
  static values = { id: String, userTimeZone: String };

  connect() {
    this.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.browserTimeZone !== this.userTimeZoneValue) {
      this.showModal(this.idValue);

      this.detectedTimeZoneTarget.textContent = this.browserTimeZone;
    }
  }

  async keepCurrentTimezone() {
    await this.submit(this.userTimeZoneValue);
  }

  async changeCurrentTimezone() {
    await this.submit(this.browserTimeZone);
  }

  async submit(timeZone) {
    const response = await patch("/users/update_time_zone", {
      body: { time_zone: timeZone },
    });
    if (response.ok) {
      this.hideModal(this.idValue);
    }
  }

  showModal(modalId) {
    const modal = document.getElementById(modalId);
    document.getElementById("backdrop").style.display = "block";
    document.querySelector("body").classList.add("modal-open");
    modal.style.display = "block";
    modal.classList.add("show");
  }

  hideModal(modalId) {
    const modal = document.getElementById(modalId);
    document.getElementById("backdrop").style.display = "none";
    document.querySelector("body").classList.remove("modal-open");
    modal.style.display = "none";
    modal.classList.remove("show");
  }
}
