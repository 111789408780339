import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectedLang"];
  static values = { removeSelectedCoach: Boolean };

  initialize() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get("language")) {
      this.selectedLangTarget.value = urlParams.get("language");
    }
  }

  selectLang(event) {
    let href = window.location.pathname + "?language=" + event.target.value;

    if (this.removeSelectedCoachValue) {
      href += "&remove_selected_coach=true";
    }
    window.location.href = href;
  }
}
